import { createStore } from 'vuex'

export default createStore({
  state: {
    item: {}
  },
  mutations: {
    setItem(state, item) {
      console.log(item);
      state.item = item;
    }
  },
  actions: {
    displayItemDetails(context, item){
      context.commit('setItem', item);
    }
  },
  modules: {
  }
})
