<template>
<error-boundary stopPropagation>
  <div class="app-wrapper">
    <div id="nav">
      <router-link to="/">Home</router-link><span class ="white-text"> | </span>
      <router-link to="/about">About</router-link>
    </div>
    <div class="router-view">
      <router-view/>
    </div>
    <span class="footer"><a href="https://www.oakgrovebaptistchurch.org/" target="_blank">OGBC</a> Sermon Archive</span>
  </div>
</error-boundary>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
  /* used for navbar */
#nav {
  padding: 15px;
  margin-bottom: 25px;
  background: #000080;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
}

#nav a {
  font-weight: bold;
  color: white;
}

/* used for nav links when hovered */
#nav a:hover {
  font-weight: bold;
  color: #00ffff;
}

#nav a.router-link-exact-active {
  color: #808080;
  text-decoration: none;
}

/* used for the application wrapper div */
.app-wrapper {
  background: whitesmoke;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

/* used for used to set proper space between active screen and the navbar */
.router-view {
  margin-top: 50px;
}

.white-text {
  color: white;
}

/* used for footer text */
.footer {
  font-size: 10px;
  bottom: 0;
  left: 0;
  position: fixed;
}
</style>

<script>

import ErrorBoundary from './components/ErrorBoundary.vue';

export default {
  components: {
    ErrorBoundary
  },
};
</script>
