<!-- HTML template -->
<template>
  <div>
    <slot
      v-if="err"
      name="error"
      v-bind:err="err"
      v-bind:vm="vm"
      v-bind:info="info"
    >
        <h1>Something went wrong...</h1>
        <p>This content could not be displayed. Please return <a href="/">home</a></p>
    </slot>
    <slot v-else></slot>
  </div>
</template>

<script>
/* 
  export component
  prop stopPropagation Boolean flag that determines if error(s) should be stopped by this boundary
*/
export default {
  name: "error-boundary",
  props: {
    stopPropagation: Boolean
  },
  // component data
  data() {
    return {
      // error being caught
      err: false,
      // vue application instance
      vm: null,
      // error information
      info: null
    };
  },
  /** 
    handle method for errorCaptured hook
    param error being caught
    param vm vue application instance
    param info error information
  */
  errorCaptured(err, vm, info) {
    this.err = err;
    this.vm = vm;
    this.info = info;
    return !this.stopPropagation;
  }
};
</script>