<template>
  <div align="center" class="component-container">
      <h2>Sermon Archive</h2>
      <select v-model="this.searchType" class="form-select-custom" @focus="focus('searchType')" @blur="blur()">
          <option value="passage">Search by Passage</option>
          <option value="topic">Search by Topic</option>
      </select>
      <div v-if="this.searchType == 'passage'">
          <div class="form-field">
            <label for="input-book" :class="{'form-label-input-error': this.errors.book, 'form-label-input-focus': this.focusedField == 'book' || this.book, 'form-label': true}">Book</label>
            <input id="input-book" :class="{'form-input-in-use': this.book, 'form-input': true, 'form-input-error': this.errors.book}" v-model="this.book" @input="displayBookSuggestions()" @focus="focus('book')" @blur="blur()" />
            <span class="form-error">{{ errors.book }}</span>
          </div>

          <div v-if="this.autocompleteBooks.length > 0" class="autocomplete-box">
              <div v-for="suggestion, index in this.autocompleteBooks" :key="index" class="autocomplete-row" @click="autocompleteBook(suggestion)">
                  {{ suggestion }}
              </div>
          </div>

          <div class="form-field">
            <label for="input-chapter" :class="{'form-label-input-error': this.errors.chapter, 'form-label-input-focus': this.focusedField == 'chapter' || this.chapter, 'form-label': true}">Chapter</label>
            <input id="input-chapter" :class="{'form-input-in-use': this.chapter, 'form-input': true, 'form-input-error': this.errors.chapter}" v-model="this.chapter" @focus="focus('chapter')" @blur="blur()" />
            <span class="form-error">{{ errors.chapter }}</span>
          </div>

          <div class="form-field">
            <label for="input-verse" :class="{'form-label-input-error': this.errors.verse, 'form-label-input-focus': this.focusedField == 'verse' || this.verse, 'form-label': true}">Verse</label>
            <input id="input-verse" :class="{'form-input-in-use': this.verse, 'form-input': true, 'form-input-error': this.errors.verse}" v-model="this.verse" @focus="focus('verse')" @blur="blur()" />
            <span class="form-error">{{ errors.verse }}</span>
          </div>
      </div>
      <div v-else>
          <div class="form-field">
          <label for="input-topic" :class="{'form-label-input-error': this.errors.topic, 'form-label-input-focus': this.focusedField == 'topic' || this.topic, 'form-label': true}">Topic</label>
          <input id="input-topic" :class="{'form-input-in-use': this.topic, 'form-input': true, 'form-input-error': this.errors.topic}" v-model="this.topic" @focus="focus('topic')" @blur="blur()" />
          <span class="form-error">{{ errors.topic }}</span>
          </div>
      </div>
      <button class="form-button" @click.prevent="search($event)">SEARCH</button>
      <div v-if="this.items.length">
        <div v-for="item in this.items" :key="item.id">
            <div class="item-container">
                <span class="bold-text">
                    {{ item.title }} 
                </span><br>
                Passage: {{ item.passage }} <br>
                Preacher: {{ item.speaker }} <br>
                <p> Topics: <span v-for="(topic, index) in item.topics" :key="topic.id">{{ topic.name }}<span v-if="!(index == item.topics.length - 1)">, </span></span></p>
                <a :href="item.link" target="_blank" class="sermon-link">Watch</a> | <a class="sermon-link" @click.prevent="openDetails(item)">Details</a>
            </div>
        </div>
      </div>
      <div v-else>No matching content found for that search.</div>
  </div>
</template>

<style scoped>
    /* used for the component's display area */
    .component-container {
        height: 100vh;
        overflow: scroll;
    }

    /* used for the fields of the form */
    .form-field {
        padding: 20px;
        width: min-content;
        position: relative;
    }

    /* used for the labels of the form */
    .form-label {
        display: block;
        float: left;
        position: absolute;
    }

    /*  used to chansge the appearance of form labels if their field has an error */
    .form-label-input-error {
        font-size: 8px;
        position: relative;
        color: red;
    }

    /*  used when a label's field is focused*/
    .form-label-input-focus {
        font-size: 8px;
        position: relative;
        color: #000080
    }

    /* used for form input elements when their field has an error */
    .form-input-error {
        color: red;
        border-color: red;
    }

    /*  used for form error text */
    .form-error {
        color: red;
    }


    /* used for form input elements */
    .form-input {
        width: 200px;
        border-top: none;
        border-left: none;
        border-right: none;
        font-family: inherit;
        font-size: 16px;
        background: whitesmoke;
        color: transparent;
    }
    /* used to for form input elements when they are focused */
    .form-input:focus {
        outline: none;
        border-color: #000080;
        color: inherit;
    }

    /* used for form input elements when their field is in use */
    .form-input-in-use {
        color: inherit;
    }

    /* used for form buttons */
    .form-button {
        background: whitesmoke;
        color: #000080;
        border: 2px solid #000080;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        padding: 6px 24px;
        margin-bottom: 10px;
    }

    /* used for form buttons when they are hovered over */
    .form-button:hover {
        background: #000080;
        color: white;
        border: 2px solid #000080;
    }

    /* used for form buttons when they are focused */
    .form-button:focus {
        background: #000080;
        color: white;
        border: 2px solid #000080;
        outline: none;
    }

    /* 
        used for form select elements
        named as *_custom to avoid conflict with bootstrap CSS
     */
    .form-select-custom {
        width: 200px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom-width: 2px;
        border-radius: none;
        box-shadow: none;
        background: whitesmoke;
    }

    /* used for form select elements when they are focused */
    .form-select-custom:focus {
        outline: none;
        border-color: #000080;
        color: inherit;
    }

    /* media query used for form buttons when the user lacks the ability to hover */
    @media (hover:none) {
        .form-button {
            background: #000080;
            color: white;
            border: 2px solid #000080;
        }
    }

    .item-container {
        margin-bottom: 25px;
    }

    .sermon-link {
        color: blue;
        cursor: pointer;
    }

    .sermon-link:hover {
        color: purple;
    }

    .bold-text {
        font-weight: bold;
    }

    .autocomplete-box {
        background-color: white;
        max-height: 250px;
        max-width: 250px;
        overflow-y: scroll;
        border-radius: 8px;
    }

    .autocomplete-row {
        cursor: pointer;
        text-align: left;
    }

    .autocomplete-row:hover {
        cursor: pointer;
        background-color: whitesmoke;
        text-decoration: underline;
    }

</style>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: 'Items',

  data() {
    return {
      // list of sermons
      items: [],
      searchType: "passage",
      book: "",
      chapter: "",
      verse: "",
      topic: "",
      focusedField: "",

      errors: {
          book: "",
          chapter: "",
          verse: "",
          topic: ""
      },

      autocompleteBooks: [],

      books: ["Genesis",
                "Exodus",
                "Leviticus",
                "Numbers",
                "Deuteronomy",
                "Joshua",
                "Judges",
                "Ruth",
                "1 Samuel",
                "2 Samuel",
                "1 Kings",
                "2 Kings",
                "1 Chronicles",
                "2 Chronicles",
                "Ezra",
                "Nehemiah",
                "Esther",
                "Job",
                "Psalm",
                "Proverbs",
                "Ecclesiastes",
                "Song of Solomon",
                "Isaiah",
                "Jeremiah",
                "Lamentations",
                "Ezekiel",
                "Daniel",
                "Hosea",
                "Joel",
                "Amos",
                "Obadiah",
                "Jonah",
                "Micah",
                "Nahum",
                "Habakkuk",
                "Zephaniah",
                "Haggai",
                "Zechariah",
                "Malachi",
                "Matthew",
                "Mark",
                "Luke",
                "John",
                "Acts",
                "Romans",
                "1 Corinthians",
                "2 Corinthians",
                "Galatians",
                "Ephesians",
                "Philippians",
                "Colossians",
                "1 Thessalonians",
                "2 Thessalonians",
                "1 Timothy",
                "2 Timothy",
                "Titus",
                "Philemon",
                "Hebrews",
                "James",
                "1 Peter",
                "2 Peter",
                "1 John",
                "2 John",
                "3 John",
                "Jude",
                "Revelation"]
    }
  },

  methods: {
      search(event) {
          // validate the form contents
          if(this.validate()) {
              const baseUrl = "https://ogbcaapi.org/content/";
              let uri = "";
              if (this.searchType == "passage") {
                  if (this.verse == "") {
                      uri = "passage?book=" + this.book + "&chapter=" + this.chapter;
                  }

                  else {
                      uri = "passage?book=" + this.book + "&chapter=" + this.chapter + "&verse=" + this.verse;
                  }
              
                }

                else if (this.searchType == "topic") {
                    uri = "topic/" + this.topic;
                }
                axios.get(baseUrl + uri)
                .then((response) => {
                            this.items = response.data.data
                        }, (error) => {
                            console.log(error);
                            if (error.response.status == 404) {
                                this.items = [];
                            }
                        });
            }
            // invalid form content
          else {
              // blur the form submit button
              event.target.blur();
          }
      },

      validate() {
          if (this.searchType == "passage") {

              const bookRegex = "^[a-zA-Z0-9 ]+$";
              this.errors.book = this.book.length > 0 && this.book.length <= 32 && this.book.match(bookRegex) ? "" : "Must be 1-32 characters";
              
              const chapterAndVerseRegex = "[0-9]+";
              this.errors.chapter = this.chapter.match(chapterAndVerseRegex) && parseInt(this.chapter) > 0 && parseInt(this.chapter) <= 150 ? "" : "Must be a number from 1-150";
              this.errors.verse = (this.verse == "" || (this.verse.match(chapterAndVerseRegex) && parseInt(this.verse) > 0 && parseInt(this.verse) <= 176)) ? "" : "Must be a number from 1-176";

              return (this.errors.book == "" && this.errors.chapter == "" && this.errors.verse == "");
          }

          else if (this.searchType == "topic") {
              const topicRegex = "[a-zA-Z ]+$";
              this.errors.topic = this.topic.length > 0 && this.topic.length <= 128 && this.topic.match(topicRegex) ? "" : "Must be consist of letters and spaces and be from 1-128 characters";
              return this.errors.topic == "";
          }

          // invalid search type
          return false;
      },

      displayBookSuggestions(){
          let matchingBooks = [];

          for (let index in this.books) {
              if (this.books[index].toUpperCase().includes(this.book.toUpperCase()) && this.book.length > 0) {
                  matchingBooks.push(this.books[index]);
              }

          }

          this.autocompleteBooks = matchingBooks;
      },

      autocompleteBook(book){
          this.setBook(book);
          this.autocompleteBooks = [];
      },

      setBook(book){
          this.book = book;
      },

      openDetails(item) {
          this.$store.dispatch("displayItemDetails", item);
          this.$router.push("/sermon");
          window.scrollTo(0, 0);
      },

      focus(field) {
          // update focused field data
        this.focusedField = field;
      },

      /**
       * This method clears the focused field tracker
       */
      blur() {
          // seet focused field data to empty string
        this.focusedField = "";
      },
  },
  
  components: {
  },

  /**
   * Lifecycle hook that runs when the compnent is created
   */
  created() {
      // Send GET request for reviews
      axios.get('https://ogbcaapi.org/content')
              .then((response) => {
                    // request was successful
                    // store returned reviews in component data
                        this.items = response.data.data
                    }, (error) => {
                    console.log(error);
                    });
  }
}
</script>
